import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';

// components
import Navbar from './components/Navbar';

// pages
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home'
import Login from './pages/Login';
import Books from './pages/Books';
import Experience from './pages/Experience';
import Collaborations from './pages/Collaborations';

function App() {

  const { user, authIsReady } = useAuthContext();

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {user && <Navbar />}
          <Switch>
            <Route exact path="/">
              {!user && <Redirect to='/login' />}
              {user && <Home />}
            </Route>
            <Route exact path="/forgotpassword">
              {user && <Redirect to='/' />}
              {!user && <ForgotPassword />}
            </Route>
            <Route exact path="/login">
              {user && <Redirect to='/' />}
              {!user && <Login />}
            </Route>
            <Route exact path="/books">
              {!user && <Redirect to='/login' />}
              {user && <Books />}
            </Route>
            <Route exact path="/experience">
              {!user && <Redirect to='/login' />}
              {user && <Experience />}
            </Route>
            <Route exact path="/collaborations">
              {!user && <Redirect to='/login' />}
              {user && <Collaborations />}
            </Route>
            <Route path="/">
              <Redirect to='/' />
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App
