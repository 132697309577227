import { useState, useEffect } from 'react';

// firebase imports
import { auth } from '../firebase/config';
import { sendPasswordResetEmail } from "firebase/auth";

export const useForgotPassword = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [success, setSuccess] = useState(false);

    const forgotPassword = async (email) => {
        setError(null);
        setIsPending(true);
        setSuccess(false);

        try {
            // send rest password email
            await sendPasswordResetEmail(auth, email);

            if (!isCancelled) {
                setIsPending(false)
                setSuccess(true)
                setError(null)
            }
        }
        catch (err) {
            if (!isCancelled) {
                setError(err.message)
                setIsPending(false)
                setSuccess(false)
            }
        }
    }


    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { forgotPassword, isPending, error, success };
};