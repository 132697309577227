import React from 'react';
import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';

export default function Navbar() {
  const { logout } = useLogout();
  return (
    <header className="text-gray-600 body-font sticky top-0 bg-white pt-2 pb-2 z-10">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
              <Link to="/" className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]">Home</Link>
              <Link to="/books" className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]">Books</Link>
              <Link to="/experience" className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]">Experience</Link>
              <Link to="/collaborations" className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]">Collaborations</Link>
              <button onClick={logout} className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">Logout</button>
            </nav>
        </div>
    </header>
  )
}
