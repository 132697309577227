import { useState, useEffect } from "react";

import { db, storage } from "../firebase/config";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function Home() {
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [bgColor, setBgColor] = useState("bg-amber-200");
  const [headingFont, setHeadingFont] = useState([{ font: 'Dancing Script' }]);
  const [email, setEmail] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);

      if (thumbnail !== null) {
        // start uploading image if exists
        // upload user thumbnail
        const uploadPath = `${thumbnail.name}`;
        const storageRef = ref(storage, uploadPath);
        await uploadBytes(storageRef, thumbnail);
        const imgUrl = await getDownloadURL(storageRef);
        const docRef = doc(db, "Website/hero");
        setDoc(docRef, {
          name: name,
          email: email,
          subtitle: bio,
          facebook: facebook,
          instagram: instagram,
          linkedin: linkedIn,
          twitter: twitter,
          imageUrl: imgUrl,
          fonts: headingFont,
          bgColor: bgColor,
        }).then((_) => {
          setIsLoading(false);
          setMessage("Home Section Saved Successfully!");
        });
      } else {
        const docRef = doc(db, "Website/hero");
        setDoc(docRef, {
          name: name,
          email: email,
          subtitle: bio,
          facebook: facebook,
          instagram: instagram,
          linkedin: linkedIn,
          twitter: twitter,
          imageUrl: imageUrl,
          fonts: headingFont,
          bgColor: bgColor,
        }).then((_) => {
          setIsLoading(false);
          setMessage("Home Section Saved Successfully!");
        });
      }
    }
  };

  const handleProfileImageFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];

    if (!selected) {
      return;
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("Selected File must be an image.");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    setImageUrl(URL.createObjectURL(selected));
  };

  useEffect(() => {
    const docRef = doc(db, "Website/hero");
    getDoc(docRef).then((ss) => {
      setName(ss.get("name"));
      setBio(ss.get("subtitle"));
      setEmail(ss.get("email"));
      setImageUrl(ss.get("imageUrl"));
      setInstagram(ss.get("instagram"));
      setLinkedIn(ss.get("linkedin"));
      setFacebook(ss.get("facebook"));
      setTwitter(ss.get("twitter"));
      setBgColor(ss.get("bgColor"));
      setHeadingFont(ss.get("fonts"));
    });
  }, []);

  return (
    <section className="text-gray-600 body-font relative">
      <form onSubmit={handleSubmit}>
        <div className="container mx-auto grid px-5 py-8 items-center justify-center justify-items-center w-full">
          <div className="bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Home Section
            </h2>
            <p class="leading-relaxed mb-5 text-gray-600">
              Don't forget to click on update button after making changes in all
              fields to update the website. <br /> Note: Changes will be
              reflected immediately on the website as soon as you click on
              update button.
            </p>
            {/* {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">Invalid Email or Password</span>
          </div>} */}

            <img src={imageUrl} width={200} height={200} alt="" />

            {/* thumbnail field */}
            <div className="relative mb-4">
              <label
                htmlFor="thumbnail"
                className="leading-7 text-sm text-gray-600"
              >
                Profile Image
              </label>
              <input
                type="file"
                onChange={handleProfileImageFileChange}
                id="thumbnail"
                name="thumbnail"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {thumbnailError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{thumbnailError}</span>
                </div>
              )}
            </div>

            {/* name textfield */}
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                required
                type="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* name font textfield */}
            <div className="relative mb-4">
              <label htmlFor="namefont" className="leading-7 text-sm text-gray-600">
                Name Font
              </label>
              <input
                required
                type="name"
                onChange={(e) => setHeadingFont([{font: e.target.value}])}
                value={headingFont[0]['font']}
                id="namefont"
                name="namefont"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* bg color textfield */}
            <div className="relative mb-4">
              <label htmlFor="bgcolor" className="leading-7 text-sm text-gray-600">
                Background Color
              </label>
              <input
                required
                type="name"
                onChange={(e) => setBgColor(e.target.value)}
                value={bgColor}
                id="bgcolor"
                name="bgcolor"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* bio textfield */}
            <div className="relative mb-4">
              <label htmlFor="bio" className="leading-7 text-sm text-gray-600">
                Bio
              </label>
              <input
                required
                type="bio"
                onChange={(e) => setBio(e.target.value)}
                value={bio}
                id="bio"
                name="bio"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* email textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* linkedin textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="linkedin"
                className="leading-7 text-sm text-gray-600"
              >
                LinkedIn Profile Link
              </label>
              <input
                required
                type="linkedin"
                onChange={(e) => setLinkedIn(e.target.value)}
                value={linkedIn}
                id="linkedin"
                name="linkedin"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* facebook textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="facebook"
                className="leading-7 text-sm text-gray-600"
              >
                Facebook Profile Link
              </label>
              <input
                required
                type="facebook"
                onChange={(e) => setFacebook(e.target.value)}
                value={facebook}
                id="facebook"
                name="facebook"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* instagram textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="instagram"
                className="leading-7 text-sm text-gray-600"
              >
                Instagram Profile Link
              </label>
              <input
                required
                type="instagram"
                onChange={(e) => setInstagram(e.target.value)}
                value={instagram}
                id="instagram"
                name="instagram"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* twitter textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="twitter"
                className="leading-7 text-sm text-gray-600"
              >
                Twitter Profile Link
              </label>
              <input
                required
                type="twitter"
                onChange={(e) => setTwitter(e.target.value)}
                value={twitter}
                id="twitter"
                name="twitter"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {message && (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{message}</span>
              </div>
            )}

            <button className="text-white bg-red-500 border-0 my-2 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">
              {isLoading ? "Loading..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
