import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC4BJ-VnzWvzhxiU4tzewJ6I2oLCSBfgUg",
    authDomain: "barbara-vokatis.firebaseapp.com",
    projectId: "barbara-vokatis",
    storageBucket: "barbara-vokatis.appspot.com",
    messagingSenderId: "179355199074",
    appId: "1:179355199074:web:528a516534b3c406c6533b"
};

// init firebase
initializeApp(firebaseConfig);

// init firebase auth
const auth = getAuth();

// init firestore
const db = getFirestore();

// init storage
const storage = getStorage();

export { db, auth, storage };