import { useState, useEffect } from "react";


import { db, storage } from "../firebase/config";
import { getDoc, setDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";



export default function Collaborations() {
    const [name, setName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");
    const [allCollaborations, setAllCollaborations] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);


  const [imageUrl, setImageUrl] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  
  
    const handleSubmit = async (e) => {
        
      e.preventDefault();
      setIsSubmitting(true);
        // start uploading book cover image
        const uploadPath = `collabs/${thumbnail.name}`;
        const storageRef = ref(storage, uploadPath);
        await uploadBytes(storageRef, thumbnail);
        const imgUrl = await getDownloadURL(storageRef);
    
        const docRef = doc(db, "Website/collaborations");
        setDoc(docRef, {
            cards: [
            {
              name: name,
              description: description,
              link: link,
              imageUrl: imgUrl,
              occupation: occupation,
            },
            ...allCollaborations,
          ],
        }).then((_) => {
          setIsSubmitting(false);
          window.location.reload();
        });
    };
  
    function deleteCollab(collabIndex) {
      const docRef = doc(db, "Website/collaborations");
        setDoc(docRef, {
         cards: allCollaborations.filter((item, index) => index !== collabIndex),
        }).then((_) => {
          window.location.reload();
        });
    }
  
    useEffect(() => {
      setIsLoading(true);
      const docRef = doc(db, "Website/collaborations");
      getDoc(docRef).then((ss) => {
        let collabsList = [];
        ss.get("cards").forEach((element) => {
          collabsList.push(element);
        });
        setAllCollaborations(collabsList);
        setIsLoading(false);
      });
    }, []);


    const handleBookCoverImageFileChange = (e) => {
        setThumbnail(null);
        setImageUrl(null);
        let selected = e.target.files[0];
    
        if (!selected) {
          setThumbnailError("Please select book cover image.");
          return;
        }
    
        if (!selected.type.includes("image")) {
          setThumbnailError("Selected File must be an image.");
          return;
        }
    
        setThumbnailError(null);
        setThumbnail(selected);
        setImageUrl(URL.createObjectURL(selected));
      };
  
    return (
        <section className="text-gray-600 body-font relative">
          <div className="container mx-auto grid px-5 py-8 items-center justify-center justify-items-center w-full">
          <form onSubmit={handleSubmit}>
            <div className="container mx-auto grid px-5 pb-16 items-center justify-center justify-items-center w-full">
              <div className="bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
                <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                  Add Collaboration
                </h2>
                {/* {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">Invalid Email or Password</span>
            </div>} */}
    
                <img src={imageUrl} width={200} height={200} alt="" />
    
                {/* thumbnail field */}
                <div className="relative mb-4">
                  <label
                    htmlFor="thumbnail"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Collab Photo
                  </label>
                  <input
                    required
                    type="file"
                    onChange={handleBookCoverImageFileChange}
                    id="thumbnail"
                    name="thumbnail"
                    className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  {thumbnailError && (
                    <div
                      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <span className="block sm:inline">{thumbnailError}</span>
                    </div>
                  )}
                </div>
    
                {/* name textfield */}
                <div className="relative mb-4">
                  <label
                    htmlFor="Name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    required
                    type="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    id="name"
                    name="name"
                    className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
    
                {/* description textfield */}
                <div className="relative mb-4">
                  <label
                    htmlFor="description"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Description
                  </label>
                  <textarea
                    required
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    id="description"
                    name="description"
                    className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
    
                {/* occupation textfield */}
                <div className="relative mb-4">
                  <label
                    htmlFor="occupation"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Occupation
                  </label>
                  <input
                    required
                    type="text"
                    onChange={(e) => setOccupation(e.target.value)}
                    value={occupation}
                    id="occupation"
                    name="occupation"
                    className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>

                {/* Link textfield */}
                <div className="relative mb-4">
                  <label
                    htmlFor="link"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Link
                  </label>
                  <input
                    required
                    type="text"
                    onChange={(e) => setLink(e.target.value)}
                    value={link}
                    id="link"
                    name="link"
                    className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
    
                <button className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">
                  {isSubmitting ? "Loading..." : "Add"}
                </button>
              </div>
            </div>
          </form>
            <div className="bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font mb-4">
                Collaboration Section
              </h2>
              {/* {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">Invalid Email or Password</span>
            </div>} */}
    
              <table className="table-auto">
                <thead className="justify-start justify-items-start items-start">
                  <tr className="justify-start justify-items-start items-start">
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Collab Photo
                    </th>
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Name
                    </th>
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Description
                    </th>
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Occupation
                    </th>
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Link
                    </th>
                    
                    <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && <div className="p-8">Loading, please wait...</div>}
                  {allCollaborations &&
                    allCollaborations.map((item, index) => (
                      <tr key={index}>
                        <td className="text-start align-top border border-slate-700 p-2">
                          <img
                            src={item.imageUrl}
                            width={200}
                            height={200}
                            alt="book cover"
                          />
                        </td>
                        <td className="text-start align-top border border-slate-700 p-2">
                          {item.name}
                        </td>
                        <td className="text-start align-top border border-slate-700 p-2">
                          {item.description}
                        </td>
                        <td className="text-start align-top border border-slate-700 p-2">
                          {item.occupation}
                        </td>
                        <td className="text-start align-top border border-slate-700 p-2">
                          <a href={item.link} className="text-red-500">
                            {item.link}
                          </a>
                        </td>
                        <td className="text-start align-top border border-slate-700 p-2">
                          <span
                            className="material-symbols-outlined cursor-pointer"
                            onClick={() => deleteCollab(index)}
                          >
                            delete
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
    
     
        </section>
      );
}
