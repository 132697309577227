import { useState, useEffect } from "react";

import { db, storage } from "../firebase/config";
import { getDoc, setDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function Books() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buylink, setBuylink] = useState("");
  const [allBooks, setAllBooks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // start uploading book cover image
    const uploadPath = `books/${thumbnail.name}`;
    const storageRef = ref(storage, uploadPath);
    await uploadBytes(storageRef, thumbnail);
    const imgUrl = await getDownloadURL(storageRef);

    const docRef = doc(db, "Website/books");
    setDoc(docRef, {
      books: [
        {
          name: title,
          description: description,
          buylink: buylink,
          bookcover: imgUrl,
        },
        ...allBooks,
      ],
    }).then((_) => {
      setIsSubmitting(false);
      window.location.reload();
    });
  };

  function deleteBook(expIndex) {
    const docRef = doc(db, "Website/books");
    setDoc(docRef, {
      books: allBooks.filter((item, index) => index !== expIndex),
    }).then((_) => {
      window.location.reload();
    });
  }

  const handleBookCoverImageFileChange = (e) => {
    setThumbnail(null);
    setImageUrl(null);
    let selected = e.target.files[0];

    if (!selected) {
      setThumbnailError("Please select book cover image.");
      return;
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("Selected File must be an image.");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    setImageUrl(URL.createObjectURL(selected));
  };

  useEffect(() => {
    setIsLoading(true);
    const docRef = doc(db, "Website/books");
    getDoc(docRef).then((ss) => {
      let booksList = [];
      ss.get("books").forEach((element) => {
        booksList.push(element);
      });
      setAllBooks(booksList);
      setIsLoading(false);
    });
  }, []);

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container mx-auto grid px-5 py-8 items-center justify-center justify-items-center w-full">
      <form onSubmit={handleSubmit}>
        <div className="container mx-auto grid px-5 pb-16 items-center justify-center justify-items-center w-full">
          <div className="bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Add Book
            </h2>
            {/* {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">Invalid Email or Password</span>
        </div>} */}

            <img src={imageUrl} width={200} height={200} alt="" />

            {/* thumbnail field */}
            <div className="relative mb-4">
              <label
                htmlFor="thumbnail"
                className="leading-7 text-sm text-gray-600"
              >
                Book Cover Image
              </label>
              <input
                required
                type="file"
                onChange={handleBookCoverImageFileChange}
                id="thumbnail"
                name="thumbnail"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {thumbnailError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{thumbnailError}</span>
                </div>
              )}
            </div>

            {/* title textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="title"
                className="leading-7 text-sm text-gray-600"
              >
                Title
              </label>
              <input
                required
                type="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                id="title"
                name="title"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* description textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="description"
                className="leading-7 text-sm text-gray-600"
              >
                Description
              </label>
              <textarea
                required
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                id="description"
                name="description"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            {/* buy Link textfield */}
            <div className="relative mb-4">
              <label
                htmlFor="buylink"
                className="leading-7 text-sm text-gray-600"
              >
                Buy Link
              </label>
              <input
                required
                type="text"
                onChange={(e) => setBuylink(e.target.value)}
                value={buylink}
                id="buylink"
                name="buylink"
                className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>

            <button className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">
              {isSubmitting ? "Loading..." : "Add"}
            </button>
          </div>
        </div>
      </form>
        <div className="bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font mb-4">
            Books Section
          </h2>
          {/* {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">Invalid Email or Password</span>
        </div>} */}

          <table className="table-auto">
            <thead className="justify-start justify-items-start items-start">
              <tr className="justify-start justify-items-start items-start">
                <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                  Cover Image
                </th>
                <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                  Title
                </th>
                <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                  Description
                </th>
                <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                  Buy Link
                </th>
                <th className="border-collapse border border-slate-500 text-start p-2 border-spacing-px">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div className="p-8">Loading, please wait...</div>}
              {allBooks &&
                allBooks.map((item, index) => (
                  <tr key={index}>
                    <td className="text-start align-top border border-slate-700 p-2">
                      <img
                        src={item.bookcover}
                        width={200}
                        height={200}
                        alt="book cover"
                      />
                    </td>
                    <td className="text-start align-top border border-slate-700 p-2">
                      {item.name}
                    </td>
                    <td className="text-start align-top border border-slate-700 p-2">
                      {item.description}
                    </td>
                    <td className="text-start align-top border border-slate-700 p-2">
                      <a href={item.buylink} className="text-red-500">
                        {item.buylink}
                      </a>
                    </td>
                    <td className="text-start align-top border border-slate-700 p-2">
                      <span
                        className="material-symbols-outlined cursor-pointer"
                        onClick={() => deleteBook(index)}
                      >
                        delete
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

 
    </section>
  );
}
