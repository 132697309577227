import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForgotPassword } from '../hooks/useForgotPassword';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const { success, error, isPending, forgotPassword } = useForgotPassword();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await forgotPassword(email);
        setEmail('');
    };

    return (
        <section className="text-gray-600 body-font relative">
            <form onSubmit={handleSubmit}>
                <div className="container mx-auto grid px-5 py-24 items-center justify-center justify-items-center w-full">
                    <div className="sm:w-1/2 w-full bg-white rounded-lg p-8 flex flex-col w-full relative shadow-md">
                        <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Forgot Password?</h2>
                        <p class="leading-relaxed mb-5 text-gray-600">Please enter your login email, we will send you password reset link on your email, so you can reset your password.</p>
                        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">Invalid email or something went wrong, please try again.</span>
                        </div>}
                        {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">Email sent, check your inbox!</span>
                        </div>}
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input
                                required
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email} id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        {!isPending &&
                            <button className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">Submit</button>
                        }
                        {isPending && <button className="text-white bg-red-500 border-0 py-2 px-6 rounded text-lg" disabled>Loading...</button>}
                        <Link to="/login" class="text-red-500 mt-2 inline-flex items-center md:mb-2 lg:mb-0">Remember Password? Login here
                            <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M5 12h14"></path>
                                <path d="M12 5l7 7-7 7"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </form>
        </section>
    );
}
